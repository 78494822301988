<template>
  <div class="main-content">
    <b-card>
      <good-data-table 
        ref="office_shift" 
        :columns="columns" :no-serial="true"
        :total-column="'response.responseData.total'" 
        :data-column="'response.responseData.data'"
        :api-endpoint="'/hrm/office_shift'"
        @add-new-info="New_Office_Shift" 
        :buttonLabel="'Add'"
      />
      <validation-observer ref="Create_Office_Shift">
        <b-modal hide-footer size="lg" id="New_Office_Shift" :title="editmode ? $t('Edit') : $t('Add')">
          <b-form @submit.prevent="Submit_Office_Shift">
            <b-row>
              <!-- Name -->
              <b-col md="12">
                <validation-provider name="Name" :rules="{ required: true }" v-slot="validationContext">
                  <b-form-group :label="$t('Name') + ' ' + '*'">
                    <b-form-input :placeholder="$t('Enter Shift name')" :state="getValidationState(validationContext)"
                      aria-describedby="Name-feedback" label="Name" v-model="office_shift.name"></b-form-input>
                    <b-form-invalid-feedback id="Name-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
            <b-row>
              <!-- Monday_In  -->
              <b-col cols="12" md="2" class="d-flex align-items-center">
                <b-form-checkbox v-model="toggle.monday" :name="$t('Monday')" switch inline>
                  <span>{{ $t('Monday') }}</span>
                </b-form-checkbox>
              </b-col>
              <b-col md="4">
                <b-form-group :label="$t('Monday In')">
                  <b-form-timepicker v-model="office_shift.monday_in" locale="en" :disabled="!toggle.monday" />
                </b-form-group>
              </b-col>

              <!-- Monday_Out  -->
              <b-col md="4">
                <b-form-group :label="$t('Monday Out')">
                  <b-form-timepicker v-model="office_shift.monday_out" locale="en" :disabled="!toggle.monday" />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="2" class="d-flex align-items-center">
                <b-form-checkbox v-model="toggle.tuesday" :name="`${$t('Tuesday')}`" switch inline>
                  <span>{{ $t('Tuesday') }}</span>
                </b-form-checkbox>
              </b-col>
              <!-- Tuesday_In  -->
              <b-col md="4">
                <b-form-group :label="$t('Tuesday In')">
                  <b-form-timepicker v-model="office_shift.tuesday_in" locale="en" :disabled="!toggle.tuesday" />
                </b-form-group>
              </b-col>

              <!-- tuesday_out  -->
              <b-col md="4">
                <b-form-group :label="$t('Tuesday out')">
                  <b-form-timepicker v-model="office_shift.tuesday_out" locale="en" :disabled="!toggle.tuesday" />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="2" class="d-flex align-items-center">
                <b-form-checkbox v-model="toggle.wednesday" :name="`${$t('Wednesday')}`" switch inline>
                  <span>{{ $t('Wednesday') }}</span>
                </b-form-checkbox>
              </b-col>
              <!-- wednesday_in  -->
              <b-col md="4">
                <b-form-group :label="$t('Wednesday in')">
                  <b-form-timepicker v-model="office_shift.wednesday_in" locale="en" :disabled="!toggle.wednesday" />
                </b-form-group>
              </b-col>

              <!-- wednesday_out  -->
              <b-col md="4">
                <b-form-group :label="$t('Wednesday out')">
                  <b-form-timepicker v-model="office_shift.wednesday_out" locale="en" :disabled="!toggle.wednesday" />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="2" class="d-flex align-items-center">
                <b-form-checkbox v-model="toggle.thursday" :name="`${$t('Thursday')}`" switch inline>
                  <span>{{ $t('Thursday') }}</span>
                </b-form-checkbox>
              </b-col>
              <!-- thursday_in  -->
              <b-col md="4">
                <b-form-group :label="$t('Thursday in')">
                  <b-form-timepicker v-model="office_shift.thursday_in" locale="en" :disabled="!toggle.thursday" />
                </b-form-group>
              </b-col>

              <!-- thursday_out  -->
              <b-col md="4">
                <b-form-group :label="$t('Thursday out')">
                  <b-form-timepicker v-model="office_shift.thursday_out" locale="en" :disabled="!toggle.thursday" />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="2" class="d-flex align-items-center">
                <b-form-checkbox v-model="toggle.friday" :name="`${$t('Friday')}`" switch inline>
                  <span>{{ $t('Friday') }}</span>
                </b-form-checkbox>
              </b-col>
              <!-- friday_in  -->
              <b-col md="4">
                <b-form-group :label="$t('Friday in')">
                  <b-form-timepicker v-model="office_shift.friday_in" locale="en" :disabled="!toggle.friday" />
                </b-form-group>
              </b-col>

              <!-- friday_out  -->
              <b-col md="4">
                <b-form-group :label="$t('Friday out')">
                  <b-form-timepicker v-model="office_shift.friday_out" locale="en" :disabled="!toggle.friday" />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="2" class="d-flex align-items-center">
                <b-form-checkbox v-model="toggle.saturday" :name="`${$t('Saturday')}`" switch inline>
                  <span>{{ $t('Saturday') }}</span>
                </b-form-checkbox>
              </b-col>
              <!-- saturday_in  -->
              <b-col md="4">
                <b-form-group :label="$t('Saturday in')">
                  <b-form-timepicker v-model="office_shift.saturday_in" locale="en" :disabled="!toggle.saturday" />
                </b-form-group>
              </b-col>

              <!-- saturday_out  -->
              <b-col md="4">
                <b-form-group :label="$t('Saturday out')">
                  <b-form-timepicker v-model="office_shift.saturday_out" locale="en" :disabled="!toggle.saturday" />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="2" class="d-flex align-items-center">
                <b-form-checkbox v-model="toggle.sunday" :name="`${$t('Sunday')}`" switch inline>
                  <span>{{ $t('Sunday') }}</span>
                </b-form-checkbox>
              </b-col>
              <!-- sunday_in  -->
              <b-col md="4">
                <b-form-group :label="$t('Sunday in')">
                  <b-form-timepicker v-model="office_shift.sunday_in" locale="en" :disabled="!toggle.sunday" />
                </b-form-group>
              </b-col>

              <!-- sunday_out  -->
              <b-col md="4">
                <b-form-group :label="$t('Sunday out')">
                  <b-form-timepicker v-model="office_shift.sunday_out" locale="en" :disabled="!toggle.sunday" />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="12" class="mt-3">
                <b-button variant="primary" type="submit" :disabled="SubmitProcessing"><i
                    class="i-Yes me-2 font-weight-bold"></i> {{ $t('submit') }}</b-button>
                <div v-once class="typo__p" v-if="SubmitProcessing">
                  <div class="spinner sm spinner-primary mt-3"></div>
                </div>
              </b-col>
            </b-row>
          </b-form>
        </b-modal>
      </validation-observer>
    </b-card>
  </div>
</template>


<script>
import VueClockPicker from '@pencilpix/vue2-clock-picker';
import GoodDataTable from '@/@core/layouts/shared/GoodDataTable.vue'
import Vue from 'vue'
import axios from 'axios'
import jwtDefaultConfig from '@/@core/auth/jwt/jwtDefaultConfig'
const Fire = new Vue();
const apiUrl = `${jwtDefaultConfig.apiEndpoint}/hrm`;
import { hideLoader, showErrorNotification, showLoader, showSuccessNotification } from '@/@core/comp-functions/ui/app'
const _days = [
  'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'
];
import { isEmpty } from '@/utilities';
export default {
  metaInfo: {
    title: "Office Shift"
  },


  data() {
    return {
      isLoading: true,
      SubmitProcessing: false,
      serverParams: {
        columnFilters: {},
        sort: {
          field: "id",
          type: "desc"
        },
        page: 1,
        perPage: 10
      },
      selectedIds: [],
      totalRows: "",
      search: "",
      limit: "10",
      office_shifts: [],
      companies: [],
      editmode: false,
      office_shift: {
        name: "",
        monday_in: "",
        monday_out: "",
        tuesday_in: "",
        tuesday_out: "",
        wednesday_in: "",
        wednesday_out: "",
        thursday_in: "",
        thursday_out: "",
        friday_in: "",
        friday_out: "",
        saturday_in: "",
        saturday_out: "",
        sunday_in: "",
        sunday_out: "",
      },
      toggle: {
        sunday: false,
        monday: false,
        tuesday: false,
        wednesday: false,
        thursday: false,
        friday: false,
        saturday: false,
      }
    };
  },

  computed: {
    columns() {
      return [
        {
          label: this.$t("Name"),
          field: "name",
          
        },
        {
          label: 'Action',
          field: 'action',
          sortable: false,
          type: 'dropdown',
          actions: [
            {
              isIcon: true,
              iconHtml: this.$helpers.getIcons('edit'),
              text: 'Edit',
              action: props => {
                this.Edit_Office_Shift(props)
              },
            },
            {
              isIcon: true,
              iconHtml: this.$helpers.getIcons('delete'),
              text: 'Delete',
              action: props => {
                this.$swal({
                  title: this.$t('You want to delete?'),
                  iconHtml: this.$helpers.swalIcon('alert.png'),
                  showCancelButton: true,
                  confirmButtonText: this.$t('Yes'),
                  cancelButtonText: this.$t('No'),
                  customClass: {
                    confirmButton: 'btn btn-success',
                    cancelButton: 'btn btn-danger ml-1',
                    icon: 'border-0',
                  },
                  buttonsStyling: false,
                }).then(result => {
                  if (result.value) {
                    this.Remove_Office_Shift(props)
                  }
                })
              },
            },
          ],
        },
      ];
    }
  },
  components: {
    VueClockPicker,
    GoodDataTable
  },

  methods: {
    //---- update Params Table
    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },

    //---- Event Page Change
    onPageChange({ currentPage }) {
      if (this.serverParams.page !== currentPage) {
        this.updateParams({ page: currentPage });
        this.Get_Office_Shift(currentPage);
      }
    },

    //---- Event Per Page Change
    onPerPageChange({ currentPerPage }) {
      if (this.limit !== currentPerPage) {
        this.limit = currentPerPage;
        this.updateParams({ page: 1, perPage: currentPerPage });
        this.Get_Office_Shift(1);
      }
    },

    //---- Event Select Rows
    selectionChanged({ selectedRows }) {
      this.selectedIds = [];
      selectedRows.forEach((row, index) => {
        this.selectedIds.push(row.id);
      });
    },

    //---- Event Sort Change

    onSortChange(params) {
      let field = params[0].field;
      this.updateParams({
        sort: {
          type: params[0].type,
          field: params[0].field
        }
      });
      this.Get_Office_Shift(this.serverParams.page);
    },

    //---- Event Search
    onSearch(value) {
      this.search = value.searchTerm;
      this.Get_Office_Shift(this.serverParams.page);
    },

    //---- Validation State Form
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },

    //------------- Submit Validation Create & Edit Office_Shift
    Submit_Office_Shift() {
      this.$refs.Create_Office_Shift.validate().then(success => {
        if (!success) {
          showErrorNotification(this, 'Please fill the form correctly');
        } else {
          if (!this.editmode) {
            this.Create_Office_Shift();
          } else {
            this.Update_Office_Shift();
          }
        }
      });
    },

    //------------------------------ Modal (create Office_Shift) -------------------------------\\
    New_Office_Shift() {
      this.reset_Form();
      this.Get_Data_Create();
      this.editmode = false;
      this.$bvModal.show("New_Office_Shift");
    },

    //------------------------------ Modal (Update Office_Shift) -------------------------------\\
    Edit_Office_Shift(office_shift) {
      this.Get_Office_Shift(this.serverParams.page);
      this.reset_Form();
      this.editmode = true;
      this.Get_Data_Edit(office_shift.id);
      this.office_shift = office_shift;
      _days.forEach(day => {
        this.toggle[day] = true
        if(isEmpty(this.office_shift[`${day}_in`]) && isEmpty(this.office_shift[`${day}_out`])){
          this.toggle[day] = false
        }
      })
      this.$bvModal.show("New_Office_Shift");
    },

    //---------------------- Get_Data_Create  ------------------------------\\
    Get_Data_Create() {
      axios
        .get(apiUrl + "/office_shift/create")
        .then(response => {
          this.companies = response.data.companies;
        })
        .catch(error => {

        });
    },

    //---------------------- Get_Data_Edit  ------------------------------\\
    Get_Data_Edit(id) {
      axios
        .get(apiUrl + "/office_shift/" + id + "/edit")
        .then(response => {
          this.companies = response.data.companies;
        })
        .catch(error => {

        });
    },

    Selected_Company(value) {
      if (value === null) {
      }
    },

    //--------------------------Get ALL office_shift ---------------------------\\

    Get_Office_Shift(page) {
      // Start the progress bar.
      axios
        .get(
          apiUrl + "/office_shift?page=" +
          page +
          "&SortField=" +
          this.serverParams.sort.field +
          "&SortType=" +
          this.serverParams.sort.type +
          "&search=" +
          this.search +
          "&limit=" +
          this.limit
        )
        .then(response => {
          this.office_shifts = response.data.office_shifts;
          this.totalRows = response.data.totalRows;

          // Complete the animation of theprogress bar.
          this.isLoading = false;
        })
        .catch(response => {
          // Complete the animation of theprogress bar.
          setTimeout(() => {
            this.isLoading = false;
          }, 500);
        });
    },

    //------------------------------- Create office_shift ------------------------\\
    Create_Office_Shift() {

      this.SubmitProcessing = true;
      const params = {
        name: this.office_shift.name
      }
      _days.forEach(day => {
        params[`${day}_in`] = this.toggle[day] ? this.office_shift[`${day}_in`] : null;
        params[`${day}_out`] = this.toggle[day] ? this.office_shift[`${day}_out`] : null;
      })
      axios
        .post(apiUrl + "/office_shift", params)
        .then(response => {
          this.SubmitProcessing = false;
          Fire.$emit("Event_Office_Shift");
          showSuccessNotification(this, 'Created successfully!');
        })
        .catch(error => {
          this.SubmitProcessing = false;
          showErrorNotification(this, "Invalid Data")
        });
    },

    //------------------------------- Update office_shift ------------------------\\
    Update_Office_Shift() {
      this.SubmitProcessing = true;
      const params = {
        name: this.office_shift.name
      }
      _days.forEach(day => {
        params[`${day}_in`] = this.toggle[day] ? this.office_shift[`${day}_in`] : null;
        params[`${day}_out`] = this.toggle[day] ? this.office_shift[`${day}_out`] : null;
      })
      axios
        .put(apiUrl + "/office_shift/" + this.office_shift.id, params)
        .then(response => {
          this.SubmitProcessing = false;
          Fire.$emit("Event_Office_Shift");
          showSuccessNotification(this, 'Updated successfully!');
        })
        .catch(error => {
          this.SubmitProcessing = false;
          showErrorNotification(this, "Invalid Data")
        });
    },

    //------------------------------- reset Form ------------------------\\
    reset_Form() {
      this.office_shift = {
        id: "",
        name: "",
        monday_in: "",
        monday_out: "",
        tuesday_in: "",
        tuesday_out: "",
        wednesday_in: "",
        wednesday_out: "",
        thursday_in: "",
        thursday_out: "",
        friday_in: "",
        friday_out: "",
        saturday_in: "",
        saturday_out: "",
        sunday_in: "",
        sunday_out: "",
      };
    },

    //------------------------------- Delete office_shift ------------------------\\
    Remove_Office_Shift(id) {
      this.$swal({
        text: '',
        title: this.$t('Please confirm you want to delete this item?'),
        iconHtml: this.$helpers.swalIcon('alert.png'),
        showCancelButton: true,
        confirmButtonText: this.$t('Yes'),
        cancelButtonText: this.$t('No'),
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger ml-1',
          icon: 'border-0',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          axios
            .delete(apiUrl + "/office_shift/" + id)
            .then(() => {
              showSuccessNotification(this, 'Deleted successfully!');

              Fire.$emit("Delete_Office_Shift");
            })
            .catch(() => {
              showErrorNotification(this, "Something went wrong while deleting item!");
            });
        }
      });
    },

    //---- Delete company by selection

    delete_by_selected() {
      this.$swal({
        text: '',
        title: this.$t('Please confirm you want to delete this item?'),
        iconHtml: this.$helpers.swalIcon('alert.png'),
        showCancelButton: true,
        confirmButtonText: this.$t('Yes'),
        cancelButtonText: this.$t('No'),
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger ml-1',
          icon: 'border-0',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          // Start the progress bar.
          axios
            .post(apiUrl + "/office_shift/delete/by_selection", {
              selectedIds: this.selectedIds
            })
            .then(() => {
              showSuccessNotification(this, 'Deleted successfully!');

              Fire.$emit("Delete_Office_Shift");
            })
            .catch(() => {
              // Complete the animation of theprogress bar.
              showErrorNotification(this, "Something went wrong while deleting item!");
            });
        }
      });
    }
  },

  //----------------------------- Created function-------------------\\

  created: function () {
    this.Get_Office_Shift(1);

    Fire.$on("Event_Office_Shift", () => {
      setTimeout(() => {
        this.Get_Office_Shift(this.serverParams.page);
        this.$bvModal.hide("New_Office_Shift");
      }, 500);
    });

    Fire.$on("Delete_Office_Shift", () => {
      setTimeout(() => {
        this.Get_Office_Shift(this.serverParams.page);
      }, 500);
    });
  }
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
